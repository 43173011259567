import React from 'react';
import {ReactNode} from 'react';
import './default.layout.scss';
import Footer from "../footer/footer";
import Navigation from "../navigation/navigation";

interface DefaultLayoutProps {
  children: ReactNode;
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({children}: DefaultLayoutProps) => {
  return (
    <div id="layout" className="default-layout">
      <Navigation/>

      <main className="default-layout__content">
        {children}
      </main>

      <Footer/>
    </div>
  );
};

export default DefaultLayout;
