import {ReactNode} from "react";
import './circle.button.scss';

interface CircleButtonProps {
  children: ReactNode;
  onClick?: () => void;
  className?: string;
}

const CircleButton: React.FC<CircleButtonProps> = ({children, onClick, className}: CircleButtonProps) => {
  return (
    <button
      className={`circle-button ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default CircleButton;