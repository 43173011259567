import React from 'react';
import AppRoutes from './routes';
import './i18n/i18n';
import {ThemeProvider} from "./components/contexts/theme.context";
import {FontSizeProvider} from "./components/contexts/font-size.context";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="app">
      <ThemeProvider>
        <FontSizeProvider>
          <AppRoutes/>
        </FontSizeProvider>
      </ThemeProvider>

      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}

export default App;