import React from 'react';
import {useTranslation} from 'react-i18next';
import DefaultLayout from "../../components/layout/default/default.layout";
import './home.page.scss';
import HeroSection from "../../components/sections/hero/hero.section";

const HomePage = () => {
  const {t} = useTranslation();

  return (
    <DefaultLayout>
      <div className="home-page">
        <HeroSection/>
      </div>
    </DefaultLayout>
  );
};

export default HomePage;