import React from 'react';
import './footer.scss';
import {useTranslation} from "react-i18next";

const Footer = () => {
  const {t} = useTranslation();

  return (
    <footer className="footer">
      © {new Date().getFullYear()} orora labs <AsciiLogo/> {t('footer.allRightsReserved')}
    </footer>
  );
};

export default Footer;

const AsciiLogo = () => {
  return (
    <>
      <div className="-ml-[5px] mt-[2px]">◠</div>
      <div className="-ml-[17px] mt-[2px] text-[8px]">◡</div>
    </>
  )
}
