import React from 'react';
import {useTranslation} from 'react-i18next';
import './language.button.scss';

const LanguageButton: React.FC = () => {
  const {i18n} = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="language-button">
      <label className="language-button__wrapper">
        <input
          type="checkbox"
          className="language-controller"
          checked={i18n.language === 'pl'}
          onChange={() => changeLanguage(i18n.language === 'en' ? 'pl' : 'en')}
        />

        <span className="swap-on">
          PL
        </span>

        <span className="swap-off">
          EN
        </span>
      </label>
    </div>
  );
};

export default LanguageButton;
