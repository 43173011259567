import React from 'react';
import {useTranslation} from 'react-i18next';
import './hero.section.scss';
import {motion} from "framer-motion";
import {useTheme} from "../../contexts/theme.context";
import TypewriterWords from "../../typewriter/typewriter-words";
import {ArrowUpRightIcon, CopyIcon} from "lucide-react";
import CircleButton from "../../buttons/circle-button/circle.button";
import {toast} from "react-toastify";

const HomePage = () => {
  const {t} = useTranslation();
  const {theme} = useTheme();

  const logoMotionVariants = {
    hidden: {y: -15, opacity: 0},
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 100,
        damping: 20,
        delay: 0.1,
      }
    }
  };

  const experienceMotionVariants = {
    hidden: {y: -15, opacity: 0},
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 100,
        damping: 20,
        delay: 0.3,
      }
    }
  };

  const emailMotionVariants = {
    hidden: {y: -15, opacity: 0},
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 100,
        damping: 20,
        delay: 0.5,
      }
    }
  };

  const experienceWords = [
    t("hero.forYou"),
    t("hero.online"),
    t("hero.modern"),
    t("hero.better"),
    t("hero.faster")
  ];

  const handleEmailClick = () => {
    window.location.href = 'mailto:biuro@ororalabs.pl';
  };

  const handleCopyEmail = async () => {
    try {
      await navigator.clipboard.writeText('biuro@ororalabs.pl');
      toast.success(t('emailCopied'));
    } catch (err) {
      console.error('Failed to copy email: ', err);
    }
  };

  return (
    <section className="hero">
      <motion.img
        src={theme === 'dark' ? '/assets/imgs/logo-white-text-only.svg' : '/assets/imgs/logo-black-text-only.svg'}
        className="hero__logo"
        alt="orora labs logo"
        variants={logoMotionVariants}
        initial="hidden"
        animate="visible"
      />

      <h1 className="sr-only">
        {`${t('hero.creatingThings')}, ${experienceWords.join(', ')}`}
      </h1>

      <motion.div
        className="hero__experience-text"
        variants={experienceMotionVariants}
        initial="hidden"
        animate="visible"
      >
          <span>
            {t('hero.creatingThings')}
            <TypewriterWords words={experienceWords}/>
          </span>
      </motion.div>

      <div className="hero__email">
        <span>biuro@ororalabs.pl</span>

        <CircleButton className="h-8 w-8" onClick={handleEmailClick}>
          <ArrowUpRightIcon className="h-6 w-6"/>
        </CircleButton>

        <CircleButton className="h-8 w-8" onClick={handleCopyEmail}>
          <CopyIcon className="h-5 w-5"/>
        </CircleButton>
      </div>
    </section>
  );
};

export default HomePage;