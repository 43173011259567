import React from 'react';
import {useFontSize} from "../../contexts/font-size.context";
import "./font-size.button.scss";

const FontSizeButton: React.FC = () => {
  const { isLargeFont, toggleFontSize } = useFontSize();

  return (
    <div className="font-size-button">
      <button

        onClick={toggleFontSize}
        aria-label={isLargeFont ? "Przywróć domyślny rozmiar czcionki" : "Zwiększ rozmiar czcionki"}
      >
        {isLargeFont ? "A-" : "A+"}
      </button>
    </div>
  );
};

export default FontSizeButton;
