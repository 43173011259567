import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';

interface FontSizeContextType {
  isLargeFont: boolean;
  toggleFontSize: () => void;
}

const FontSizeContext = createContext<FontSizeContextType | undefined>(undefined);

const getInitialFontSize = (): boolean => {
  const savedFontSize = localStorage.getItem('isLargeFont');
  return savedFontSize ? JSON.parse(savedFontSize) : false;
};

export const FontSizeProvider = ({ children }: { children: ReactNode }) => {
  const [isLargeFont, setIsLargeFont] = useState<boolean>(getInitialFontSize);

  useEffect(() => {
    const rootElement = document.documentElement;
    if (isLargeFont) {
      rootElement.classList.add('font-large');
    } else {
      rootElement.classList.remove('font-large');
    }

    localStorage.setItem('isLargeFont', JSON.stringify(isLargeFont));
  }, [isLargeFont]);

  const toggleFontSize = () => {
    setIsLargeFont((prev) => !prev);
  };

  return (
    <FontSizeContext.Provider value={{ isLargeFont, toggleFontSize }}>
      {children}
    </FontSizeContext.Provider>
  );
};

export const useFontSize = (): FontSizeContextType => {
  const context = useContext(FontSizeContext);
  if (context === undefined) {
    throw new Error('useFontSize must be used within a FontSizeProvider');
  }
  return context;
};
