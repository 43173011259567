import * as ReactDOM from 'react-dom/client';
import './index.scss';
import App from './app';
import reportWebVitals from "./report-web-vitals";
import React from 'react';

const rootElement = document.getElementById('root');

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement as HTMLElement);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

if (process.env.NODE_ENV === 'development') {
  reportWebVitals(console.log)
}