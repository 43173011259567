import React, {useEffect, useState} from "react";
import {motion} from "framer-motion";

export const TypewriterWords = (
  {
    words,
    typingSpeed = 100,
    deletingSpeed = 50,
    pauseTime = 1500,
    className,
  }: {
    words: string[];
    typingSpeed?: number;
    deletingSpeed?: number;
    pauseTime?: number;
    className?: string;
  }) => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [displayedText, setDisplayedText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const currentWord = words[currentWordIndex];
    let typingTimeout: ReturnType<typeof setTimeout>;

    if (!isDeleting) {
      if (displayedText.length < currentWord.length) {
        typingTimeout = setTimeout(() => {
          setDisplayedText(currentWord.slice(0, displayedText.length + 1));
        }, typingSpeed);
      } else {
        typingTimeout = setTimeout(() => setIsDeleting(true), pauseTime);
      }
    } else {
      if (displayedText.length > 0) {
        typingTimeout = setTimeout(() => {
          setDisplayedText(currentWord.slice(0, displayedText.length - 1));
        }, deletingSpeed);
      } else {
        setIsDeleting(false);
        setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
      }
    }

    return () => clearTimeout(typingTimeout);
  }, [displayedText, isDeleting, words, typingSpeed, deletingSpeed, currentWordIndex, pauseTime]);

  return (
    <motion.div
      className={`inline-block ${className}`}
      style={{
        textAlign: "left",
      }}
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      transition={{duration: 0.5}}
    >
      <span>{displayedText}</span>

      <motion.span
        className="blinking-cursor"
        animate={{opacity: [0, 1, 0]}}
        transition={{duration: 1, repeat: Infinity}}
      >
        |
      </motion.span>
    </motion.div>
  );
};

export default TypewriterWords;
