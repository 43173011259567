import React, {useState, useEffect} from 'react';
import './navigation.scss';
import {useTheme} from "../../contexts/theme.context";
import {motion} from 'framer-motion';
import ThemeButton from "../../buttons/theme-button/theme.button";
import LanguageButton from "../../buttons/language-button/language.button";
import FontSizeButton from "../../buttons/font-size-button/font-size.button";

const Navigation = () => {
  const {theme} = useTheme();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navVariants = {
    visible: {
      opacity: 1,
      transition: {duration: 0.3}
    },
    scrolled: {
      opacity: 0.3,
      transition: {duration: 0.3}
    }
  };

  const logoMotionVariants = {
    hidden: {y: -15, opacity: 0},
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 100,
        damping: 20,
        delay: 0.5,
      }
    }
  };

  return (
    <motion.nav
      className="navigation"
      variants={navVariants}
      animate={isScrolled ? "scrolled" : "visible"}
      initial="visible"
      whileHover={{opacity: 1}}
    >
      <div className="navigation__container">
        <motion.img
          src={theme === 'dark' ? '/assets/imgs/logo-small-white.svg' : '/assets/imgs/logo-small-black.svg'}
          className="navigation__logo"
          alt="orora labs logo"
          variants={logoMotionVariants}
          initial="hidden"
          animate="visible"
        />

        <div className="navigation__actions">
          <FontSizeButton/>
          <LanguageButton/>
          <ThemeButton/>
        </div>
      </div>
    </motion.nav>
  );
};

export default Navigation;
